/********* LAYOUT AND MENU RULES **********/

.layout {
    background-color: $bg-main;
}



//Altezza icone in base a h dell'header con funzione?

//Effetti su icone hover, selected ecc