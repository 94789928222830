$primary-color: #ffc107;// #F8E16C;
$secondary-color: #6d0e0e;
$swap-color: #FFFFFF;

$bg-main: #f0f0f0;
$bg-block: #FFFFFF;

$input-bg-color: #f0f0f0;
$ant-table-bg-color: #ffc107;
$lite: #f0f0f0;
$color-swap-map: (
    #F8E16C: black,
);

$btn-bordered: #c7c7c7;