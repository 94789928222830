@import "mixin";
@import "variable";
//Font Family principale gestita con ANTD

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
    margin-top: 0; // 1
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-style: $headings-font-style;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    //color: var(--#{$prefix}heading-color, inherit);
}

h1 {
    @extend %heading;
    font-size: $h1-font-size;
}

h2 {
    @extend %heading;
    font-size: $h2-font-size;
}

h3 {
    @extend %heading;
    font-size: $h3-font-size;
}

h4 {
    @extend %heading;
    font-size: $h4-font-size;
}

h5 {
    @extend %heading;
    font-size: $h5-font-size;
}

h6 {
    @extend %heading;
    font-size: $h6-font-size;
}

//
// Headings
//
.h1 {
    @extend h1;
}

.h2 {
    @extend h2;
}

.h3 {
    @extend h3;
}

.h4 {
    @extend h4;
}

.h5 {
    @extend h5;
}

.h6 {
    @extend h6;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

@mixin primary-font($font){
    .font-pri{
        font-family: $font, $font-root;
    }
}

@mixin secondary-font($font){
    .font-sec{
        font-family: $font, $font-root;
    }
}

@mixin auxaliary-font($font){
    .font-aux{
        font-family: $font, $font-root;
    }
} 

.font-sm {
    font-size: $font-size-sm;
}

.font-lg {
    font-size: $font-size-lg;
}

.underline{
    text-decoration: underline !important;
}

@include make-map('fw', font-weight, $weights);
@include make-map('fs', font-size, $font-sizes);

/* LIST E LINK => provare a gestire prima con ANTD

//Lists

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: $dt-font-weight;
}

// 1. Undo browser default

dd {
  margin-bottom: .5rem;
  margin-left: 0; // 1
}

// Links

a {
  color: rgba(var(--#{$prefix}link-color-rgb), var(--#{$prefix}link-opacity, 1));
  text-decoration: $link-decoration;

  &:hover {
    --#{$prefix}link-color-rgb: var(--#{$prefix}link-hover-color-rgb);
    text-decoration: $link-hover-decoration;
  }
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
*/