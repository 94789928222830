.print-container {
    width: 830px;
    max-width: 830px;
    margin: 8px;
}

.h-doc {
    height: calc(100vh - 182px);
}

.doc-maxwidth {
    max-width: calc(100vw - 140px);
}

.bg-ok {
    //background-color: rgb(200, 214, 200);
    background-color: #bde3b5;
}

.bg-nok {
    //background-color: rgb(200, 214, 200);
    background-color: #d86565;
}


button {
    border-radius: 50rem !important;
}

.avatar-pri {
    color: black;
    background-color: #F8E16C;
}

.ticket {
    //margin-left: 10%;
    width: 90%;
}

.ticket-own {
    margin-left: 10%;
    width: 90%;
}

.dot-green {
    height: 10px;
    width: 10px;
    background-color: $green;
    border-radius: 50%;
    display: inline-block;
}

.dot-blue {
    height: 10px;
    width: 10px;
    background-color: $cyan;
    border-radius: 50%;
    display: inline-block;
}

.dot-dark {
    height: 10px;
    width: 10px;
    background-color: $gray-800;
    border-radius: 50%;
    display: inline-block;
}

.dot-gray {
    height: 10px;
    width: 10px;
    background-color: $gray-500;
    border-radius: 50%;
    display: inline-block;
}

.dot-yellow {
    height: 10px;
    width: 10px;
    background-color: $yellow;
    border-radius: 50%;
    display: inline-block;
}

.dot-red {
    height: 10px;
    width: 10px;
    background-color: $red;
    border-radius: 50%;
    display: inline-block;
}

.bg-bluelite {
    background-color: #bbf4ff;
}

.ant-spin-dot-item {
    background-color: #F8E16C !important; //#ffc105, #a505ff
}

/*.ant-input-disabled, .ant-input[disabled] {
    cursor: text;
}*/

.vinput {
    cursor: text !important;
    color: black !important;
}

.text-input {
    border-color: #8B8D8E !important;
    border-radius: 0.25rem !important;
    border-width: 1px;
    border-style: solid;
    background-color: #f0f0f0;
    color: #333333;
    padding: 7px 11px;
    line-height: 1.3em;
    font-size: 1.2em;
}

.tab-bar {
    background-color: white;
    box-shadow: 0 .125rem .25rem rgba(var(--es-body-color-rgb), .075);
    border-radius: .25rem;
}

.ant-tabs-top>.ant-tabs-nav {
    margin: 0 0 8px 0;
}

.chart {
    height: 99%;
    width: 100%;
    min-height: 0;

    @media (orientation: landscape) and (pointer: coarse) {
        min-height: calc(100vh * 2);
    }
}

/*img:before {
    content: ' ';
    display: block;
    position: absolute;
    height: 50px;
    width: 50px;
    background-image: url(ishere.jpg);
}*/